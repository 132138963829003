import { Email } from "@/models/Email";
import Http from "./httpRequest";

const request = new Http();

export const sendEmail = (email: Email) : Promise<string> => {
    return request.post({
      url: 'email',
      data: email
    });
  };