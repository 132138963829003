import { render } from "./Contatti.vue?vue&type=template&id=acc06c9e"
import script from "./Contatti.vue?vue&type=script&lang=ts"
export * from "./Contatti.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QImg from 'quasar/src/components/img/QImg.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QImg,QCard,QCardSection,QIcon,QSeparator,QCardActions,QBtn,QInput,QCheckbox});
