<template>
  <div class="about">
    <q-img
      alt="contatti"
      height="400px"
      src="https://images.unsplash.com/photo-1572827137848-4d7955eb93d6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
    />

    <div class="flex justify-center">
      <h4 v-if="$q.platform.is.mobile">{{ t("title") }}</h4>
      <h3 v-else>{{ t("title") }}</h3>
    </div>

    <div
      :class="[
        'row container q-pb-xl',
        $q.platform.is.mobile ? 'q-px-md' : 'q-col-gutter-xl',
      ]"
    >
      <div class="col-12 col-md-4 flex justify-center">
        <q-card flat class="full-width">
          <q-card-section class="text-center">
            <q-icon name="email" color="primary" style="font-size: 4rem;" />
            <div class="text-h6">{{ t("contact") }}</div>
          </q-card-section>

          <q-separator />

          <q-card-actions vertical>
            <q-btn
              @click="phone()"
              flat
              icon="phone"
              color="primary"
              label="+39 0875 911044"
            />
            <q-btn
              @click="openEmail()"
              flat
              icon="email"
              color="primary"
              label="luciaingiuseppe@gmail.com"
            />
            <q-btn
              @click="openMap()"
              flat
              icon="maps"
              color="primary"
              label="Via dell'olio 6, Campomarino CB"
            />
          </q-card-actions>
        </q-card>
      </div>

      <div class="col-12 col-md-4 flex justify-center">
        <q-card flat class="full-width">
          <q-card-section class="text-center">
            <q-icon name="groups" color="primary" style="font-size: 4rem;" />
            <div class="text-h6">Social</div>
          </q-card-section>

          <q-separator />

          <q-card-actions vertical>
            <q-btn
              @click="openFacebook()"
              flat
              icon="img:facebook.svg"
              label="Facebook"
            />
            <q-btn
              @click="openInstagram()"
              flat
              icon="img:instagram.svg"
              label="Instagram"
            />
          </q-card-actions>
        </q-card>
      </div>

      <div class="col-12 col-md-4 flex justify-center">
        <q-card flat class="full-width">
          <q-card-section class="text-center">
            <q-icon
              name="book_online"
              color="primary"
              style="font-size: 4rem;"
            />
            <div class="text-h6">{{ t("booking") }}</div>
          </q-card-section>

          <q-separator />

          <q-card-actions vertical>
            <q-btn
              @click="openBooking()"
              flat
              color="primary"
              icon="book_online"
              :label="t('bookNow')"
            />
          </q-card-actions>
        </q-card>
      </div>
    </div>

    <div class="row q-py-xl">
      <div class="col-12 col-md-6">
        <GoogleMap
          :api-key="googleApiKey"
          style="width: 100%; height: 500px"
          :center="center"
          :zoom="15"
        >
          <Marker :options="{ position: center }" />
        </GoogleMap>
      </div>
      <div
        :class="[
          'col-12 col-md-6 bg-secondary',
          $q.platform.is.mobile ? 'q-px-md' : 'q-px-xl',
        ]"
      >
        <h4 class="q-mb-md">{{ t("contactUs") }}</h4>
        <p>{{ t("contactUsSubHeading") }}</p>

        <div class="q-gutter-y-md">
          <div class="row q-col-gutter-md">
            <div class="col-12 col-md-6">
              <q-input outlined v-model="name" :label="t('name')" dense />
              <span class="text-red" v-if="v$.name.$error">{{
                t("errorMessage")
              }}</span>
            </div>
            <div class="col-12 col-md-6">
              <q-input outlined v-model="email" :label="t('email')" dense />
              <span class="text-red" v-if="v$.email.$error">{{
                t("errorMessage")
              }}</span>
            </div>
          </div>
          <div>
            <q-input
              outlined
              v-model="message"
              type="textarea"
              :label="t('message')"
              dense
            />
            <span class="text-red" v-if="v$.message.$error">{{
              t("errorMessage")
            }}</span>
          </div>
          <div>
            <q-checkbox
              :class="{ 'text-red': v$.privacy.$error }"
              v-model="privacy"
            >
              {{ t("privacy") }}
            </q-checkbox>
            <router-link class="q-pl-xs" :to="{ name: 'Privacy' }">{{
              t("privacyUrl")
            }}</router-link>
          </div>

          <q-btn
            @click="submit"
            color="primary"
            icon-right="send"
            :label="t('sendButton')"
            :loading="showLoader"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useMeta } from "vue-meta";
import { defineComponent, ref } from "vue";
import { useQuasar } from "quasar";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { GoogleMap, Marker } from "vue3-google-map";
import { Email } from "@/models/Email";
import { sendEmail } from "@/api/email";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: { GoogleMap, Marker },
  setup() {
    const $q = useQuasar();
    const center = { lat: 41.88421347093131, lng: 15.066897790272211 };
    const { t } = useI18n({
      messages: {
        en: {
          title: "How to find us",
          contact: "Contact",
          booking: "Bookings",
          bookNow: "Book online",
          contactUs: "Contact us",
          contactUsSubHeading:
            "Send us a message, we will be happy to help you!",
          privacy: "I declare that I have read and accept the",
          privacyUrl: "privacy policy",
          nome: "Name",
          email: "Email",
          message: "Message",
          sendButton: "Send message",
          errorMessage: "Required field",
        },
        it: {
          title: "Come trovarci",
          contact: "Contatti",
          booking: "Prenotazioni",
          bookNow: "Prenotazione online",
          contactUs: "Contattaci",
          contactUsSubHeading:
            "Inviaci un messaggio, saremo felici di aiutarti!",
          nome: "Nome",
          email: "Email",
          message: "Messaggio",
          privacy: "Dichiaro di aver letto e di accettare",
          privacyUrl: "l'informativa sulla privacy",
          sendButton: "Invia messaggio",
          errorMessage: "Campo necessario",
        },
      },
    });

    useMeta({
      title: "Contatti",
      description: "Inviaci un messaggio, saremo felici di aiutarti!",
    });

    return {
      t,
      name: ref(""),
      email: ref(""),
      message: ref(""),
      privacy: ref(false),
      center,
      showLoader: ref(false),
      v$: useVuelidate(),
      triggerPositive() {
        $q.notify({
          type: "positive",
          message:
            "Grazie per averci contattato, l'email è stata inviata correttamente. Riceverai una risposta al piu presto.",
        });
      },
    };
  },
  validations() {
    return {
      name: { required },
      email: { required, email },
      message: { required },
      privacy: { sameAs: (val: boolean) => val === true },
    };
  },
  computed: {
    googleApiKey() {
      return process.env.VUE_APP_GOOGLE_API_KEY;
    },
  },
  methods: {
    phone() {
      window.open("tel:12345", "noopener");
    },
    openBooking() {
      window.open("http://www.booking.com/Share-p4Tu6J", "_blank", "noopener");
    },
    openEmail() {
      window.open("mailto:luciaingiuseppe@gmail.com");
    },
    openFacebook() {
      window.open(
        "https://www.facebook.com/agrifarm.bedandbreakfast/",
        "_blank",
        "noopener"
      );
    },
    openInstagram() {
      window.open(
        "https://www.instagram.com/lacasaincampagna_molise/",
        "_blank",
        "noopener"
      );
    },
    openMap() {
      window.open(
        "https://www.google.com/maps/place/La+Casa+in+Campagna/@41.886641,15.0623293,17z/data=!3m1!4b1!4m8!3m7!1s0x13373512df5f2297:0x6c43b3c87dd5a645!5m2!4m1!1i2!8m2!3d41.886637!4d15.064518",
        "_blank",
        "noopener"
      );
    },
    async submit() {
      try {
        this.v$.$touch();
        if (this.v$.$error) return;
        this.showLoader = true;
        const data: Email = {
          templateId: process.env.VUE_APP_EMAIL_TEMPLATE,
          to: process.env.VUE_APP_EMAIL_TO,
          replyTo: this.email,
          data: {
            email: this.email,
            name: this.name,
            message: this.message,
          },
        };
        await sendEmail(data);

        this.email = "";
        this.name = "";
        this.message = "";
        this.privacy = false;
        this.v$.$reset();
        this.triggerPositive();
      } catch (error) {
        throw new Error(error);
      } finally {
        this.showLoader = false;
      }
    },
  },
});
</script>
