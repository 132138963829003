
import { useMeta } from "vue-meta";
import { defineComponent, ref } from "vue";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { GoogleMap, Marker } from "vue3-google-map";
import { Email } from "@/models/Email";
import { sendEmail } from "@/api/email";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: { GoogleMap, Marker },
  setup() {
    const $q = useQuasar();
    const center = { lat: 41.88421347093131, lng: 15.066897790272211 };
    const { t } = useI18n({
      messages: {
        en: {
          title: "How to find us",
          contact: "Contact",
          booking: "Bookings",
          bookNow: "Book online",
          contactUs: "Contact us",
          contactUsSubHeading:
            "Send us a message, we will be happy to help you!",
          privacy: "I declare that I have read and accept the",
          privacyUrl: "privacy policy",
          nome: "Name",
          email: "Email",
          message: "Message",
          sendButton: "Send message",
          errorMessage: "Required field",
        },
        it: {
          title: "Come trovarci",
          contact: "Contatti",
          booking: "Prenotazioni",
          bookNow: "Prenotazione online",
          contactUs: "Contattaci",
          contactUsSubHeading:
            "Inviaci un messaggio, saremo felici di aiutarti!",
          nome: "Nome",
          email: "Email",
          message: "Messaggio",
          privacy: "Dichiaro di aver letto e di accettare",
          privacyUrl: "l'informativa sulla privacy",
          sendButton: "Invia messaggio",
          errorMessage: "Campo necessario",
        },
      },
    });

    useMeta({
      title: "Contatti",
      description: "Inviaci un messaggio, saremo felici di aiutarti!",
    });

    return {
      t,
      name: ref(""),
      email: ref(""),
      message: ref(""),
      privacy: ref(false),
      center,
      showLoader: ref(false),
      v$: useVuelidate(),
      triggerPositive() {
        $q.notify({
          type: "positive",
          message:
            "Grazie per averci contattato, l'email è stata inviata correttamente. Riceverai una risposta al piu presto.",
        });
      },
    };
  },
  validations() {
    return {
      name: { required },
      email: { required, email },
      message: { required },
      privacy: { sameAs: (val: boolean) => val === true },
    };
  },
  computed: {
    googleApiKey() {
      return process.env.VUE_APP_GOOGLE_API_KEY;
    },
  },
  methods: {
    phone() {
      window.open("tel:12345", "noopener");
    },
    openBooking() {
      window.open("http://www.booking.com/Share-p4Tu6J", "_blank", "noopener");
    },
    openEmail() {
      window.open("mailto:luciaingiuseppe@gmail.com");
    },
    openFacebook() {
      window.open(
        "https://www.facebook.com/agrifarm.bedandbreakfast/",
        "_blank",
        "noopener"
      );
    },
    openInstagram() {
      window.open(
        "https://www.instagram.com/lacasaincampagna_molise/",
        "_blank",
        "noopener"
      );
    },
    openMap() {
      window.open(
        "https://www.google.com/maps/place/La+Casa+in+Campagna/@41.886641,15.0623293,17z/data=!3m1!4b1!4m8!3m7!1s0x13373512df5f2297:0x6c43b3c87dd5a645!5m2!4m1!1i2!8m2!3d41.886637!4d15.064518",
        "_blank",
        "noopener"
      );
    },
    async submit() {
      try {
        this.v$.$touch();
        if (this.v$.$error) return;
        this.showLoader = true;
        const data: Email = {
          templateId: process.env.VUE_APP_EMAIL_TEMPLATE,
          to: process.env.VUE_APP_EMAIL_TO,
          replyTo: this.email,
          data: {
            email: this.email,
            name: this.name,
            message: this.message,
          },
        };
        await sendEmail(data);

        this.email = "";
        this.name = "";
        this.message = "";
        this.privacy = false;
        this.v$.$reset();
        this.triggerPositive();
      } catch (error) {
        throw new Error(error);
      } finally {
        this.showLoader = false;
      }
    },
  },
});
