import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from "axios";

const axiosConfig: AxiosRequestConfig = {
  transformResponse: [
    (data: AxiosResponse): AxiosResponse => {
      return data;
    }
  ],
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest"
  }
};

const service = axios.create(axiosConfig);

function requestInterceptorSuccess(config: AxiosRequestConfig): AxiosRequestConfig {
  // Setting authorization header
  return config;
}

function requestInterceptorError(err: AxiosError): Promise<AxiosError> {
  return Promise.reject(err);
}

function responseInterceptorSuccess(response: AxiosResponse): AxiosResponse<unknown> {
  return response.data;
}

function responseInterceptorError(error: AxiosError): Promise<AxiosError> {
  return Promise.reject(error);
}

// For more informations see interceptors section at https://github.com/axios/axios
service.interceptors.request.use(
  config => requestInterceptorSuccess(config),
  err => requestInterceptorError(err)
);

service.interceptors.response.use(
  response => responseInterceptorSuccess(response),
  error => responseInterceptorError(error)
);

export default {
  service
};