import axios from "@/services/httpRequestService";

export default class Http {
  // eslint-disable-next-line
  public async request(params: any): Promise<any> {
    return axios.service(params);
  }

  /**
   * get
   * @param [url]
   * @param [data]
   * @returns Promise
   */
  // eslint-disable-next-line
  public get(req: any): any {
    const params: Record<string, unknown> = {
      method: "GET",
      baseURL: process.env.VUE_APP_API_URL,
      url: `/${req.url}`
    };
    return this.request(params);
  }

  /**
   * put
   * @param [url]
   * @param [data]
   * @returns Promise
   */
  // eslint-disable-next-line
  public put(req: any): any {
    const params: Record<string, unknown> = {
      method: "PUT",
      baseURL: process.env.VUE_APP_API_URL,
      url: `/${req.url}`,
      data: req.data
    };
    return this.request(params);
  }

  /**
   * post
   * @param [url]
   * @param [data]
   * @returns Promise
   */
  // eslint-disable-next-line
  public post(req: any): any {
    const params: Record<string, unknown> = {
      method: "post",
      url: `/${req.url}`,
      data: req.data,
      params: req.params,
      baseURL: process.env.VUE_APP_API_URL
    };
    return this.request(params);
  }
}
